import React from 'react'
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
import { SectionTitleWrap } from "../../company/join-our-team/join-our-team.stc"
import { AboutSectionWrap, SectionInnerLeft, SectionInnerRight } from '../web-development/about.stc'
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import Fade from 'react-reveal/Fade';
import Button from "../../../components/shared/button";
import { StaticImage } from 'gatsby-plugin-image'

//queries the serviceswebdata.json file
const Section = ({ HeadingStyle, SubHeadingStyle, Paragraph, HeadingStyleServies }) => {
    const servicesQueryData = useStaticQuery(graphql`
        query ServicesCloudDataQuery {
            servicesclouddataJson(jsonId: {eq: "cloud-content"}) {
                cloudServices {
                    title
                    classId
                    link
                    description
                    tableHeader
                    tableData
                    buttonColor
                    image {
                        childImageSharp {
                            fluid(maxWidth: 960, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    } 
                }
            }
        }    
    `)
    const serviceData = servicesQueryData.servicesclouddataJson;
    const cloudServices = serviceData.cloudServices;
    // console.log(serviceData.cloudServices)

    // const cloudContent = cloudServices.map((cloudService, index) =>
    //     <div className="col-1 offset-1" key={index}>
    //         <a style={{color: "black"}} href={cloudService.link}>{cloudService.title}</a>
    //     </div>
    // );

    const content = cloudServices.map((cloudService, index) =>
        <div className="row services" key={index} id={cloudService.classId}>
            <SectionInnerLeft>
                <SectionTitleWrap>
                    <Heading {...HeadingStyleServies}>{cloudService.title}</Heading>
                    {cloudService.description.map((textData, i) => <Text key={`story-text-${i}`} {...Paragraph}>{textData}</Text>)}
                    {cloudService.link && <Button style={{ background: cloudService.buttonColor && cloudService.buttonColor }}
                        className="button" as={Link} to={cloudService.link} layout={2}>Learn More <i className="ti-arrow-right"></i>
                    </Button>}
                </SectionTitleWrap>
                {/*<SectionTitleWrap>*/}
                {/*    <Heading {...SubHeadingStyle}>{service.subtitle}</Heading>*/}
                {/*    <Heading {...HeadingStyleServies}>{service.title}</Heading>*/}
                {/*</SectionTitleWrap>*/}
            </SectionInnerLeft>
            <SectionInnerRight style={{ marginBottom: 40 }}>
                {cloudService.image ?
                    <Fade right>
                        <img className="cloud-native-image" src={cloudService.image.childImageSharp.fluid.src} alt={cloudService.title} />
                    </Fade>
                    :
                    <Fade right>
                        <SectionTitleWrap>
                            {cloudService.tableHeader && <Heading {...SubHeadingStyle}>{cloudService.tableHeader}</Heading>}
                            <div className="row">
                                {cloudService.tableData.map((textData, i) =>
                                    <div className="col-1" key={`story-text-${i}`}>
                                        <Text {...Paragraph}>{textData}</Text>
                                    </div>)
                                }
                            </div>
                        </SectionTitleWrap>
                    </Fade>
                }

            </SectionInnerRight>
        </div>
    );

    return (
        <AboutSectionWrap>
            <div className="row service-top-section">
                <SectionInnerLeft>
                    <SectionTitleWrap>
                        <Heading style={{ marginTop: 20 }} {...HeadingStyle}>Cloud Native Development</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <StaticImage style={{ marginTop: 30 }} src={"../../../../src/data/images/cloudatacent.png"} alt="cloud native header" />
                </SectionInnerRight>
            </div>

            <div className="row services">
                <SectionInnerLeft>
                    <SectionTitleWrap style={{ marginBottom: 50 }}>
                        <Heading className="modern-cloud-app" {...HeadingStyleServies}>Modern cloud applications built from the ground up.</Heading>
                    </SectionTitleWrap>
                </SectionInnerLeft>
                <SectionInnerRight>
                    <SectionTitleWrap>
                        <Text {...Paragraph}>
                            With unparalleled reliability, scalability, speed and security there isn't another place to build and deploy applications today.
                        </Text>
                        <Text {...Paragraph}>
                            We can stay code focused with the scalability and efficiency of getting started with the cloud. Scale when required. We tap into the multitude of services that cloud providers offer to ensure you're on top of the latest technologies built on the cloud.
                            If you have older workloads that required a lift and shift into a cloud then contact us today. We are experts in IaaS, PaaS and BaaS offered by the top tier cloud providers.
                        </Text>
                    </SectionTitleWrap>
                </SectionInnerRight>
            </div>

            {/*<div className="row services">*/}
            {/*<div className="col-4 offset-1">*/}
            {/*<SectionInner>*/}
            {/*    <SectionTitleWrap>*/}
            {/*        <Heading {...SubHeadingStyle}>CLOUD NATIVE SERVICES</Heading>*/}
            {/*    </SectionTitleWrap>*/}
            {/*</SectionInner>*/}
            {/*</div>*/}
            {/*{cloudContent}*/}
            {/*</div>*/}

            {content}

        </AboutSectionWrap>
    )
}
Section.propTypes = {
    HeadingStyle: PropTypes.object,
    SubHeadingStyle: PropTypes.object,
    Paragraph: PropTypes.object,
    HeadingStyleServies: PropTypes.object,
    cloudServiceLeft: PropTypes.object
}

Section.defaultProps = {
    HeadingStyle: {
        fontSize: '60px',
        texttransform: 'capitalize',
        lineHeight: '60px',
        color: '#e4e4e4',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    },
    SubHeadingStyle: {
        fontSize: '30px',
        lineHeight: '30px',
        color: 'primary',
        fontweight: 300,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    Paragraph: {
        color: 'black',
        fontSize: '20px'
    },
    HeadingStyleServies: {
        fontSize: '50px',
        texttransform: 'capitalize',
        lineHeight: '50px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '34px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '35px',
                lineHeight: '35px'
            }
        }
    }
}

export default Section

