import React from "react"
import SEO from '../../components/seo'
import Layout from '../../containers/layout/layout'
import Section from '../../containers/services/cloud-native'

export default () => (
    <Layout headerLayout={2}>
        <SEO title="Atlasopen - Cloud Native Software Engineering" description="We develop code that runs on the cloud and on-demand on the largest cloud providers including Amazon Web Services, Google Cloud Platform and Microsoft Azure. From serverless architectures to API development, we can deploy and host code on the cloud - in all regions across the world at anytime."/>
        {/*<Banner/>*/}
        {/*<About/>*/}
        <Section/>
        {/*<JoinOurTeam/>*/}
        {/*<Testimonial/>*/}
    </Layout>
)

